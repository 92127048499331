.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: white;
}

#coming {
  font-size: 30px;
}

#mail {
  font-size: 15px;
}
